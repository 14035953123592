import React from 'react';
import { SeparatorLine } from './styles';

interface FormProps {
  labelText?: string;
  color?: string;
  background?: string;
  lineColor?: string;
  fontSize?: string;
  textPos?: 'start' | 'center' | 'end';
  childrenWidth?: string;
  marginTop?: string;
  sidePadding?: string;
  borderStyle?:
    | 'dotted'
    | 'dashed'
    | 'solid'
    | 'double'
    | 'groove'
    | 'ridge'
    | 'inset'
    | 'outset'
    | 'initial'
    | 'inherit';
}
/**
 * @param labelText Texto que aparecerá em frente a linha de separação
 * @returns Componente para separar áreas do formulário como dados pessoais, contato, endereço, etc.
 */
const Separator: React.FC<FormProps> = (props) => {
  const {
    labelText,
    color = '#a2a4a6',
    background = '#fff',
    lineColor = '#d9d5d5',
    fontSize = '14px',
    textPos = 'start',
    childrenWidth = '98.8%',
    marginTop = '20px',
    sidePadding = '0 10px',
    children,
    borderStyle = '',
  } = props;
  return (
    <SeparatorLine
      color={color}
      background={background}
      lineColor={lineColor}
      fontSize={fontSize}
      textPos={textPos}
      childrenWidth={childrenWidth}
      borderStyle={borderStyle}
      sidePadding={sidePadding}
      style={{ marginTop }}
    >
      <div className="elements" style={{ paddingLeft: '0px' }}>
        <p className="sc-iqHYGH bMXcXH me-2">
          <span>{labelText}</span>
        </p>
        {children && <div className="children">{children}</div>}
      </div>
    </SeparatorLine>
  );
};

export default Separator;
